import axios from 'axios';

export const fetchCities = () => async (dispatch) => {
    try {
        const response = await axios.get('https://admin.wowpizza.ru/api/cities');
        dispatch({ type: 'SET_CITIES', payload: response.data });
    } catch (error) {
        console.error('Failed to fetch cities:', error);
    }
};
