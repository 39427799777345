import {combineReducers} from "redux";
import basketReducer from './category-active';
import Search from "./search";
import Delivery from "./delivery";
import Filial from "./filial";
import { categoriesReducer } from './categoriesReducer';
import { productsReducer } from "./productsReducer";
import { officesReducer } from "./officesReducer";
import { ordersReducer } from "./ordersReducer";
import { optionReducer } from "./optionReducer";
import { citiesReducer } from "./citiesReducer";


const all_list = combineReducers({
    products: productsReducer,
    category: categoriesReducer,
    office: officesReducer,
    option: optionReducer,
    cities: citiesReducer,
    basket: basketReducer,
    searchText: Search,
    delivery: Delivery,
    filial: Filial,
    order_id: ordersReducer
});

export default all_list;