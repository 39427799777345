import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import {createStore, applyMiddleware} from 'redux';
import all_list from "./reducers";
import './index.css';
import App from './App';
import {thunk} from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { PersistGate } from 'redux-persist/integration/react';

const persistConfig = {
    key: 'root',
    storage: sessionStorage,
};
const persistedReducer = persistReducer(persistConfig, all_list);
const storeApp = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(storeApp);

//const storeApp = createStore(all_list, applyMiddleware(thunk));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <Provider store={storeApp}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
      </Provider>
);

