import React, {Component} from "react";
import {connect} from "react-redux";
import CartProduct from "../components/cart_products";
import Scroll from "react-scroll";
import { fetchProducts } from "../actions/productsActions";

let Element = Scroll.Element;
class Products_menu extends Component{
    componentDidMount() {
        this.props.fetchProducts();
    }
    product_list(){
        let productArray = [];
        this.props.products.map((p) =>{
            if(p.category_id === this.props.id_cat){
                productArray.push(
                    <CartProduct item={p} key={p.id}/>
                )
            }
        });
        if (productArray.length){
            return productArray;
        }else{
            return null;
        }
    }

    render(){
        if(!this.product_list()){
            return ('');
        }
        return(
            <div className="block_category">
                <Element name={this.props.name}><h2>{this.props.name}</h2></Element>
                <div className="row">
                    {this.product_list()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        products: state.products.items,
    }
}
const mapDispatchToProps = {
    fetchProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Products_menu)