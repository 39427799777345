import { useNavigate } from 'react-router';
import React from 'react';

function withRouter(Component) {
    function ComponentWithRouter(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    }
    return ComponentWithRouter;
}

export default withRouter;
