import React, { Component } from 'react';
import withRouter from './withRouter';

class BackButtonComponent extends Component {
    handleBackSite = () => {
        const { navigate } = this.props;
        if (navigate) {
            navigate(-1);
        }
    };

    render() {
        return (
            <button type="button" onClick={this.handleBackSite} name="back" className="btn btn-bs">Назад</button>
        );
    }
}

export default withRouter(BackButtonComponent);
