const initialState = {
    items: []
};

export default function (state=initialState, action){
    switch (action.type){
        case "Product_select":
            if(state.items == null){
                state.items = [];
            }
            state.items.push(
                {
                    id: action.payload.id,
                    img: action.payload.img,
                    title: action.payload.title,
                    price: parseInt(action.payload.price),
                    total_price: parseInt(action.payload.price),
                    count: 1
                }
            );
            return{
                items: [...state.items],
            };
            break;
        case "Product_plus_count":
            for (let i = 0; i < state.items.length; i++){
                if(state.items[i]['id'] === action.payload.id){
                    state.items[i]['count'] +=1;
                    state.items[i]['total_price'] += state.items[i]['price'];
                }
            }
            return{
                ...state,
                items: [...state.items],
            };
            break;
        case "Product_minus_count":
            for (let i = 0; i < state.items.length; i++){
                if(state.items[i]['id'] === action.payload.id){
                    state.items[i]['count'] -=1;
                    state.items[i]['total_price'] -= state.items[i]['price'];

                    if(state.items[i]['count'] === 0){
                        state.items.splice(i, 1);
                    }
                }
            }
            return{
                ...state,
                items: [...state.items],
            };
            break;
        case "Remove_product":
            for (let i = 0; i < state.items.length; i++){
                if(state.items[i]['id'] === action.payload.id){
                    state.items.splice(i, 1);
                }
            }
            return{
                ...state,
                items: [...state.items],
            };
            break;
        case "RESET_BASKET":
            return{
                items:null,
            };
            break;
        default:
            return state;
    }
}