import axios from 'axios';

export const fetchOptions = () => async (dispatch) => {
    try {
        const response = await axios.get('https://admin.wowpizza.ru/api/option');
        dispatch({ type: 'SET_OPTION', payload: response.data });
    } catch (error) {
        console.error('Failed to fetch options:', error);
    }
};
