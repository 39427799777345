import React, {Component} from "react";
import {connect} from "react-redux";
import Icon from '@mdi/react';
import { mdiCurrencyRub,mdiPlus,mdiMinus,mdiClose } from '@mdi/js';
import {plus_count, minus_count, remove_product} from "../actions";
import {bindActionCreators} from "redux";

class OrderBasketCartProducts extends Component{
    plus(){
        return (
            <Icon path={mdiPlus} size={0.8} />
        );
    }
    minus(){
        return(
            <Icon path={mdiMinus} size={0.8} />
        );
    }
    rub(){
        return (
            <Icon path={mdiCurrencyRub} size={0.8}/>
        );
    }
    close(){
        return(
            <Icon path={mdiClose} size={0.8}/>
        );
    }
    count(id) {
        let result = this.props.basket.find(item => item.id === id);
        return result.count;
    }
    price(id) {
        let p = this.props.basket.find(item => item.id === id);
        return p.total_price;
    }
    render(){
        return (
            <div className="basket_card_block">
                <div className="backet_card_product" key={this.props.item.id} id={this.props.item.id}>
                    <div className="basket-product-image">
                        <img src={this.props.item.img} className="backet_card-img-top" alt=""/>
                    </div>
                    <div className="basket-card-body">
                        <h5 className="card-title">{this.props.item.title}</h5>
                        <span className="delete_product" onClick={() => this.props.remove_product(this.props.item)}>{this.close()}</span>
                        <div className="basket-price">
                            <p>{this.price(this.props.item.id)} {this.rub()}</p>
                            <div key={this.props.item.id} className="btn-group" role="group">
                                <button type="button" onClick={() => this.props.minus_count(this.props.item)}
                                        className="btn-cart-product btn-cart-product-minus">{this.minus()}</button>
                                <p>{this.count(this.props.item.id)}</p>
                                <button type="button" onClick={() => this.props.plus_count(this.props.item)}
                                        className="btn-cart-product btn-cart-product-plus">{this.plus()}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        category: state.category.items,
        basket: state.basket.items,
        products: state.products.items,
        searchText: state.searchText
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({plus_count: plus_count, minus_count: minus_count, remove_product: remove_product}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(OrderBasketCartProducts);