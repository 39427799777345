import React, {Component} from "react";
import {connect} from "react-redux";
import Icon from '@mdi/react';
import { mdiCurrencyRub, mdiPlus, mdiMinus } from '@mdi/js';
import {select, plus_count, minus_count} from "../actions";
import {bindActionCreators} from "redux";

class CartProduct extends Component{
    plus(){
        return (
            <Icon path={mdiPlus} size={0.8} />
        );
    }
    minus(){
        return(
            <Icon path={mdiMinus} size={0.8} />
        );
    }
    rub(){
        return (
            <Icon path={mdiCurrencyRub} size={0.8}/>
        );
    }
    button() {
        if (this.props.basket) {
            let result = this.props.basket.find(item => item.id === this.props.item.id);
            if(result){
                return (
                    <div key={this.props.item.id} className="btn-group" role="group" aria-label="Basic example">
                        <button type="button" onClick={() => this.props.minus_count(result)}
                                className="btn-cart-product btn-cart-product-minus">{this.minus()}</button>
                        <p>{result.count}</p>
                        <button type="button" onClick={() => this.props.plus_count(result)}
                                className="btn-cart-product btn-cart-product-plus">{this.plus()}</button>
                    </div>
                );
            }else{
                return (
                    <button onClick={() => this.props.select(this.props.item)}
                            className="btn-product">{this.props.item.price} {this.rub()}</button>
                );
            }
        }else {
            return (
                <button onClick={() => this.props.select(this.props.item)}
                        className="btn-product">{this.props.item.price} {this.rub()}</button>
            );
        }
    }

    render() {
        return (
            <div className="card_product col-xl-3 col-6 col-lg-4 col-md-4" key={this.props.item.id}
                 id={this.props.item.id}>
                <div className="card-product-image">
                    <img src={this.props.item.img} className="card-img-top-product" alt=""/>
                </div>
                <div className="card-body">
                    <h5 className="card-title">{this.props.item.title}</h5>
                    {this.button()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        products: state.products.items,
        basket: state.basket.items,
        category: state.category.items,
        searchText: state.searchText
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({select: select, plus_count: plus_count, minus_count: minus_count}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(CartProduct);