import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import styled from 'styled-components';
import {check_delivery, reset_basket} from "../actions";
import {bindActionCreators} from "redux";
import { fetchOffice } from '../actions/officesActions';
import { fetchCities } from "../actions/citiesAction";
import axios from "axios";
import BackButtonComponent from '../components/button_back_for_site';
import InputMask from 'react-input-mask';
import {Link} from "react-router";

const tg = window.Telegram.WebApp;
tg.ready();

const StyledWrapper = styled.div``;
class FormOrder extends Component{
    constructor(props) {
        super(props);
        this.state = {
            cart: this.props.basket,
            order_id: null,
            bounds: '',
            name_suggest: '',
            phone: '',
            city: 'Выбор',
            name: '',
            commit: '',
            filial: '',
            street: '',
            viewportHeight: window.innerHeight,
        }
        this.inputRef = createRef();
    }
    componentDidMount() {
        if (!window.ymaps && this.props.delivery === 2 || this.props.delivery === 1 || this.state.order_id) return;

        window.ymaps.ready(() => {
            this.initializeSuggestView();
        });
        this.props.fetchOffice();
        this.props.fetchCities();
        if (tg) {
            tg.onEvent('viewportChanged', this.handleViewportChange);
        }
        this.updateViewportHeight();
    }

    componentDidUpdate(prevState) {
        if (prevState.city !== this.state.city) {
            this.updateSuggestView();
        }
    }

    componentWillUnmount() {
        if (this.suggestView) {
            this.suggestView.destroy();
            this.suggestView = null;
        }
        if (tg) {
            tg.offEvent('viewportChanged', this.handleViewportChange);
        }
    }
    initializeSuggestView() {
        const { bounds, city } = this.state;
        if (!window.ymaps && this.props.delivery === 2 || this.props.delivery === 1 || this.state.order_id) return;

        this.suggestView = new window.ymaps.SuggestView(this.inputRef.current, {
            results: 10,
            container: document.getElementById("map"),
            boundedBy: bounds,
            provider: {
                suggest: function (request, options) {
                    return window.ymaps.suggest(city + ", " + request);
                    //delete options['provider'];
                    /*return window.ymaps.suggest(request, options).then(items => {
                        let arrayResult = [];
                        let arrayPromises = [];

                        function pushGeoData(displayName, value, fa) {
                            arrayResult.push({displayName: displayName, value: value, fullAddress: fa});
                        }
                        let ii = 1;
                        items.forEach(i => {
                            let dn = i.displayName;
                            if(dn.indexOf(city) < 0) return;
                            // проверяем есть ли подъезд в адресе
                            if(dn.indexOf('подъезд ') > -1)return;

                            if(ii < 8) {
                                pushGeoData(dn, dn, i.value);
                                ii++;
                            }
                        });

                        return Promise.all(arrayPromises).then(function(){
                            return window.ymaps.vow.resolve(arrayResult);
                        });
                    });*/
                }
            }
        });
        this.suggestView.events.add('select', (e) => {
            const address = e.get('item').value;
            this.setState({street: address});
            if (this.props.onSelect) {
                this.props.onSelect(address);
            }
        });
    }
    updateSuggestView() {
        if (this.suggestView) {
            this.suggestView.destroy();
        }
        this.initializeSuggestView();
    }

    handleCreateOrder = (event) => {
        event.preventDefault();
        if(this.state.name && this.state.phone) {
            let office = 0;
            if(this.state.filial && this.props.delivery !== 2){
                office = this.state.filial;
            }
            let order = [{
                city: this.state.city,
                street: this.state.street,
                name: this.state.name,
                phone: this.state.phone,
                commit: this.state.commit,
                delivery: this.props.delivery,
                filial: office,
                cart: this.state.cart
            }];
            axios.post('https://admin.wowpizza.ru/api/orders', order).then((resp) => {
                this.setState({order_id: resp.data});
                this.props.reset_basket(this.props.basket);
            });
            console.log(order);
            console.log(this.state.order_id);
        }
    }
    orderbutton(){
        if(this.props.basket && this.props.basket.length){
            return (
                <div className="block_basket">
                    <BackButtonComponent />
                    <button type="submit" onClick={this.handleCreateOrder} name="checkout" className="btn btn-primary btn-basket">Оформить заказ</button>
                </div>
            )
        }
    }
    handleChangeForm = (event) => {
        const { id, value } = event.target;
        this.setState({ [id]: value });
    };
    handleCheckFilial = (event) => {
        this.setState({filial: event.target.id});
    };
    handleChangePhone = (event) => {
        this.setState({ phone: event.target.value });
    };
    handleCitySelect = (event) => {
        let coords_new = '';
        this.props.city.forEach((c) => {
            if (c.title === event.target.value) {
                coords_new = c.coords;
            }
        });
        this.setState({
            bounds: coords_new,
            city: event.target.value,
        });
    };
    updateViewportHeight = () => {
        this.setState({ viewportHeight: window.innerHeight });
    };

    handleViewportChange = ({ isExpanded, viewportHeight }) => {
        this.setState({
            viewportHeight: viewportHeight || window.innerHeight,
        });
    };

    render() {
        let disabled = true;
        if(this.state.bounds){
            disabled = false;
        }
        const { viewportHeight } = this.state;
        if(this.state.order_id){
            const text = this.props.option.map((o)=>{ return o.text});
            return(
                <div className="order_basket">
                    <h1>Заказ оформлен</h1>
                    <p>{text} {this.state.order_id}</p>
                    <Link to="/">Вернуться на главную</Link>
                </div>
            )
        }
        if(this.props.delivery === 1) {
            return (
                <div className="order_basket">
                    <h1>Введите ваши данные</h1>
                    <form className="form-order" style={{
                        height: `${viewportHeight}px`,
                        overflowY: 'auto',
                        boxSizing: 'border-box',
                    }}>
                        <ul className="btn-select-dlv">
                            <li onClick={() => this.props.check_delivery(2)}>Доставка</li>
                            <li className={`active`} onClick={() => this.props.check_delivery(1)}>Самовывоз</li>
                        </ul>
                        <StyledWrapper>
                            <div className="radio-container">
                                {this.props.office.map((fil) => (
                                    <div className="radio-wrapper" key={fil.id}>
                                        <label className="radio-button">
                                            <input id={fil.id}
                                                   name="radio-group"
                                                   type="radio"
                                                   onChange={this.handleCheckFilial}
                                            />
                                            <span className="radio-checkmark"/>
                                            <span className="radio-label">{fil.title}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </StyledWrapper>
                        <div className="form-group">
                            <input type="text" className="form-control" id="name" onChange={this.handleChangeForm} placeholder="Имя Фамилия"/>
                        </div>
                        <div className="form-group">
                            <InputMask
                                mask="+7 (999) 999-99-99"
                                value={this.state.phone}
                                onChange={this.handleChangePhone}>
                                {(inputProps) => (
                                    <input
                                        className="form-control"
                                        {...inputProps}
                                        id="phone"
                                        type="tel"
                                        placeholder="+7 (___) ___-__-__"
                                    />
                                )}
                            </InputMask>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" id="commit" onChange={this.handleChangeForm}
                                      placeholder="Комментарий к заказу"></textarea>
                        </div>
                        {this.orderbutton()}
                    </form>
                </div>
            );
        } else {
            return (
                <div className="order_basket">
                    <h1>Введите ваши данные</h1>
                    <form className="form-order" style={{
                        height: `${viewportHeight}px`,
                        overflowY: 'auto',
                        boxSizing: 'border-box',
                    }}>
                        <ul className="btn-select-dlv">
                            <li className={`active`} onClick={() => this.props.check_delivery(2)}>Доставка</li>
                            <li onClick={() => this.props.check_delivery(1)}>Самовывоз</li>
                        </ul>
                        <div>
                            <div className="form-group">
                                <select className="form-control" id="city" value={this.state.city}
                                        onChange={this.handleCitySelect}>
                                    <option value="Выбор" disabled>Выберите город</option>
                                    {this.props.city.map(city => (
                                        <option key={city.id} value={city.title}>{city.title}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <input type="text" ref={this.inputRef} className="form-control" id="street"
                                       placeholder="Улица" disabled={disabled}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" id="name" onChange={this.handleChangeForm}
                                   placeholder="Имя Фамилия"/>
                        </div>
                        <div className="form-group">
                            <InputMask
                                mask="+7 (999) 999-99-99"
                                value={this.state.phone}
                                onChange={this.handleChangePhone}>
                                {(inputProps) => (
                                    <input
                                        className="form-control"
                                        {...inputProps}
                                        id="phone"
                                        type="tel"
                                        placeholder="+7 (___) ___-__-__"
                                    />
                                )}
                            </InputMask>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" id="commit" onChange={this.handleChangeForm}
                                      placeholder="Комментарий к заказу">
                            </textarea>
                        </div>
                        {this.orderbutton()}
                    </form>
                    <div className="none" id="map"></div>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        basket: state.basket.items,
        delivery: state.delivery,
        office: state.office.items,
        order_id: state.order_id.items,
        option: state.option.items,
        city: state.cities.items,
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({check_delivery: check_delivery, fetchOffice, reset_basket, fetchCities}, dispatch)
}

export default connect(mapStateToProps,matchDispatchToProps)(FormOrder);

