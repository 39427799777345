import React from 'react';
import {Link} from "react-router";

function Logo(props){
    const logo = props.logo.map((item)=>{
        return item.logo;
    });
    return(
        <div className="logo">
            <Link to="/">
                <img  src={logo} alt=""/>
            </Link>
        </div>
    );
}

export default Logo;