import React, {Component} from "react";
import {connect} from "react-redux";
import LogoComponents from "../components/LogoComponents";
import FormOrder from "../containers/form-order";
import { fetchOptions } from "../actions/optionActions";

class AddOrder extends Component{
    componentDidMount() {
        if(!this.props.option.length){
            this.props.fetchOptions();
        }
    }
    render() {
        return (
            <div className="container">
                <LogoComponents logo={this.props.option} />
                <FormOrder />
            </div>
        )
    }
}

function mapStateToProps (state) {
    return {
        basket: state.basket.items,
        option: state.option.items,
    };
}
const mapDispatchToProps = {
    fetchOptions,
};

export default connect(mapStateToProps,mapDispatchToProps)(AddOrder)